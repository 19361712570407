export class VideoGalleryDataBuilder {
  constructor(adaptersDictionary) {
    this.adaptersDictionary = adaptersDictionary;
  }

  async getVideoData(videoInitialData) {
    const { url, provider } = videoInitialData;
    const VideoProviderAdapter = this.adaptersDictionary[provider];

    if (!VideoProviderAdapter) {
      throw new Error(
        'Não foi possível reproduzir um vídeo: provider não suportado.',
      );
    }

    const adaptedVideoData = await new VideoProviderAdapter(url).adapt();

    return { ...videoInitialData, ...adaptedVideoData };
  }
}
