// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/RelatedProductsOffers';
import PlaceholderCard from '../../Cards/Placeholder';
import autoServiceApi from '../../../utils/autoServiceApi';

export default class RelatedProductsOffersLazyCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      relatedProductsOffers: [],
      isFetching: true,
    };

    this.fetchRelatedProductsOffers =
      this.fetchRelatedProductsOffers.bind(this);
    this.getOfferAreaLink = this.getOfferAreaLink.bind(this);
    this.initCarousel = this.initCarousel.bind(this);
  }

  componentDidMount() {
    this.fetchRelatedProductsOffers();
    this.initCarousel();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  getOfferAreaLink(type) {
    const { cardAccessoriesLink, cardPartsLink } = this.props;

    if (type === 'part') {
      return cardPartsLink;
    }

    if (type === 'accessory') {
      return cardAccessoriesLink;
    }

    throw new Error(`Tipo de oferta desconhecido: ${type}`);
  }

  async fetchRelatedProductsOffers() {
    const commonParams = [{ 'q[active_eq]': true }, { per_page: 4 }];
    const partsResult = await autoServiceApi.getParts(commonParams);
    const accessoriesResult = await autoServiceApi.getAccessories(commonParams);

    const typedPartsList = partsResult.entries.map(part => ({
      ...part,
      type: 'part',
    }));

    const typedAccessoriesList = accessoriesResult.entries.map(accessory => ({
      ...accessory,
      type: 'accessory',
    }));

    const allProducts = [...typedPartsList, ...typedAccessoriesList];
    const productsListToRender = allProducts.sort(
      (x, y) => Number(y.featured) - Number(x.featured),
    );

    this.setState(() => ({
      isFetching: false,
      relatedProductsOffers: productsListToRender,
    }));
  }

  initCarousel() {
    const carousel = window.makeCarousel(this.carouselRef, {
      perPage: 4,
      perMove: 1,
      pagination: false,
      lazyLoad: 'nearby',
      preloadPages: 0,
      gap: '2rem',
      breakpoints: {
        560: {
          perPage: 1,
        },
        992: {
          perPage: 2,
        },
        1270: {
          perPage: 3,
        },
        1400: {
          perPage: 4,
        },
      },
    });

    window.matchHeight.match('.related-products-offers__carousel');

    return carousel;
  }

  render() {
    const { isFetching, relatedProductsOffers } = this.state;

    return (
      <div
        className="related-products-offers__carousel splide"
        ref={node => {
          this.carouselRef = node;
        }}
      >
        <div className="splide__track">
          <ul className="related-products-offers__list splide__list">
            {isFetching &&
              relatedProductsOffers.length === 0 &&
              [1, 2, 3, 4].map(item => (
                <li
                  className="related-products-offers__placeholder-item splide__slide"
                  key={item}
                >
                  <PlaceholderCard />
                </li>
              ))}
            {!isFetching &&
              relatedProductsOffers.length > 0 &&
              relatedProductsOffers.map(offer => (
                <li
                  className="related-products-offers__item splide__slide"
                  key={offer.slug}
                >
                  <Card
                    badge={offer.badge}
                    title={offer.title}
                    link={`${this.getOfferAreaLink(offer.type)}/${offer.slug}`}
                    image={offer.item_image.middle_image}
                    code={offer.code}
                    sealList={offer.seal_list}
                    itemOffer={offer.item_offer}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

RelatedProductsOffersLazyCarousel.defaultProps = {
  cardPartsLink: '/novos',
  cardAccessoriesLink: '/novos',
};

RelatedProductsOffersLazyCarousel.propTypes = {
  cardPartsLink: PropTypes.string,
  cardAccessoriesLink: PropTypes.string,
};
