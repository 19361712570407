// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/NewOffers';
import PlaceholderCard from '../../Cards/Placeholder';
import autoServiceApi from '../../../utils/autoServiceApi';

export default class ExclusiveOffers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newOffers: [],
      isFetching: true,
    };

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchNewOffers = this.fetchNewOffers.bind(this);
  }

  componentDidMount() {
    this.initCarousel();
    this.fetchNewOffers();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  async fetchNewOffers() {
    const result = await autoServiceApi.getOffers([]);
    let newOffersList = result
      ? result.entries.slice(0, 16)
      : this.state.newOffers;

    if (this.props.offerId) {
      newOffersList = newOffersList.filter(
        offer => parseInt(offer.id, 10) !== parseInt(this.props.offerId, 10),
      );
    }

    this.setState(() => ({
      newOffers: newOffersList,
      isFetching: false,
    }));
  }

  initCarousel() {
    const carousel = window.makeCarousel(this.carouselRef, {
      gap: 30,
      perPage: 4,
      perMove: 4,
      pagination: false,
      lazyLoad: 'nearby',
      preloadPages: 0,
      rewind: false,
      bound: true,
      focus: 'center',
      breakpoints: {
        560: {
          perPage: 1,
          perMove: 1,
        },
        992: {
          perPage: 2,
        },
        1270: {
          perPage: 3,
        },
        1400: {
          perPage: 4,
        },
      },
    });

    window.matchHeight.match('.new-offers-lazy__carousel');

    return carousel;
  }

  render() {
    const { newOffers, isFetching } = this.state;
    const { cardLink } = this.props;

    return (
      <div
        className="new-offers-lazy__carousel splide"
        data-is-fetching={isFetching}
        ref={node => {
          this.carouselRef = node;
        }}
      >
        <div className="splide__track">
          <ul className="splide__list new-offers-lazy__carousel-items-wrapper">
            {isFetching &&
              newOffers.length === 0 &&
              [1, 2, 3, 4].map(item => (
                <li
                  className="new-offers-lazy__carousel-placeholder-item carousel__item splide__slide"
                  key={item}
                >
                  <PlaceholderCard
                    customClasses="new-offers-lazy__placeholder-card"
                    showBody={false}
                  />
                </li>
              ))}
            {!isFetching &&
              newOffers.length > 0 &&
              newOffers.map(offer => (
                <li
                  className="new-offers-lazy__carousel-item splide__slide"
                  key={offer.slug}
                >
                  <Card
                    title={offer.title}
                    subtitle={offer.complement ? offer.complement : offer.brand}
                    image={offer.item_image.middle_image}
                    link={`${cardLink}/${offer.slug}`}
                    sealList={offer.seal_list}
                    badge={offer.item_offer.badge}
                    description={offer.item_offer.description}
                    extra={offer.item_offer.extra}
                    featured={offer.item_offer.featured}
                    mainCall={offer.item_offer.main_call}
                    secondaryCall={offer.item_offer.secondary_call}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

ExclusiveOffers.defaultProps = {
  cardLink: '/ofertas',
  offerId: null,
};

ExclusiveOffers.propTypes = {
  cardLink: PropTypes.string,
  offerId: PropTypes.number,
};
