// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';

export default class RelatedProductsOffersCard extends Component {
  render() {
    const { badge, title, link, image, code, sealList, itemOffer } = this.props;

    return (
      <div className="related-products-offers-card">
        <div className="related-products-offers-card__header">
          {badge && (
            <span className="related-products-offers-card__badge">{badge}</span>
          )}
          <a className="related-products-offers-card__image-link" href={link}>
            <img
              className="related-products-offers-card__image"
              src={image}
              width={225}
              height={130}
              alt={title}
            />
          </a>
        </div>

        <div className="related-products-offers-card__content">
          <h4
            className="related-products-offers-card__title"
            data-match-height="related-products-offers-card__title"
          >
            {title}
          </h4>
          <ul
            className="related-products-offers-card__list"
            data-match-height="related-products-offers-card__list"
          >
            {code && (
              <li className="related-products-offers-card__list-item">
                Código: {code}
              </li>
            )}
            {sealList.length >= 1 &&
              sealList.map(seal => (
                <li
                  className="related-products-offers-card__list-item"
                  key={seal}
                >
                  {seal}
                </li>
              ))}
          </ul>
        </div>

        <div className="related-products-offers-card__footer">
          <div
            className="related-products-offers-card__triggers"
            data-match-height="related-products-offers-footer"
          >
            {itemOffer.secondary_call && (
              <p className="related-products-offers-card__price-trigger">
                {itemOffer.secondary_call}
              </p>
            )}
            {itemOffer.main_call && (
              <p className="related-products-offers-card__price-main-call">
                {itemOffer.main_call}
              </p>
            )}
            {itemOffer.featured && (
              <p className="related-products-offers-card__price-featured">
                {itemOffer.featured}
              </p>
            )}
            {itemOffer.description && (
              <p className="related-products-offers-card__price-description">
                {itemOffer.description}
              </p>
            )}
            {itemOffer.extra && (
              <p className="related-products-offers-card__price-extra">
                {itemOffer.extra}
              </p>
            )}
          </div>

          <a
            href={link}
            className="btn button button--primary button--large related-products-offers-card__cta"
          >
            Estou interessado
          </a>
        </div>
      </div>
    );
  }
}

RelatedProductsOffersCard.defaultProps = {
  badge: null,
  title: null,
  link: null,
  image: null,
  code: null,
  itemOffer: null,
};

RelatedProductsOffersCard.propTypes = {
  badge: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  code: PropTypes.string,
  sealList: PropTypes.arrayOf(PropTypes.string),
  itemOffer: PropTypes.objectOf({
    secondary_call: PropTypes.string,
  }),
};
