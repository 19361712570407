/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Component } from 'preact';
import PropTypes from 'prop-types';
import { makeVideoGalleryDataBuilder } from '%/utils/videoGallery';
import VideoPlayerModal from './VideoPlayerModal';

export default class TurboVideoGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      currentVideo: null,
      activeSlideBeforeDestroy: 0,
      videosData: [],
    };

    this.initCarousel = this.initCarousel.bind(this);
    this.setVideos = this.setVideos.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    this.initCarousel();
    this.setVideos();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  handleOpenModal(video) {
    if (this.carousel) this.carousel.destroy();
    this.setState(oldState => ({
      ...oldState,
      modalIsOpen: true,
      currentVideo: video,
    }));
  }

  handleCloseModal() {
    if (this.carousel) this.carousel.destroy();
    this.setState(oldState => ({
      ...oldState,
      modalIsOpen: false,
      currentVideo: null,
    }));
  }

  async setVideos() {
    const { videos } = this.props;

    const videosDataPromise = videos.map(async video => {
      const videoGalleryDataBuilder = makeVideoGalleryDataBuilder();
      const videoDataPromise = await videoGalleryDataBuilder.getVideoData(
        video,
      );

      return videoDataPromise;
    });

    const resolvedVideosData = await Promise.all(videosDataPromise);

    this.setState(oldState => ({
      ...oldState,
      videosData: resolvedVideosData,
    }));
  }

  initCarousel() {
    const carousel = window.makeCarousel(this.galleryRef, {
      perPage: 2,
      perMove: 1,
      gap: '2rem',
      pagination: true,
      start: this.state.activeSlideBeforeDestroy,
      breakpoints: {
        560: {
          perPage: 1,
        },
      },
    });

    carousel.on('destroy', () => {
      this.setState(oldState => ({
        ...oldState,
        activeSlideBeforeDestroy: carousel.Components.Controller.getIndex(),
      }));
    });

    this.carousel = carousel;
  }

  render() {
    const { modalIsOpen, currentVideo, videosData } = this.state;

    return (
      <div className="video-gallery__carousel-wrapper">
        <div
          className="video-gallery__carousel splide"
          ref={node => {
            this.galleryRef = node;
          }}
          aria-label="Galeria de vídeos"
        >
          <div className="splide__track">
            <ul className="splide__list video-gallery__carousel-items-wrapper">
              {videosData.length > 0 &&
                videosData.map(video => (
                  <li
                    key={video.id}
                    className="video-gallery__item splide__slide"
                  >
                    <img
                      className="video-gallery__item-image"
                      src={video.thumb}
                      alt="Thumb do vídeo do veículo"
                    />
                    <button
                      className="video-gallery__video-controls"
                      onClick={() => this.handleOpenModal(video)}
                      type="button"
                    >
                      <span
                        className="video-gallery__play-button"
                        data-open-modal
                        data-target-id="modal-video-gallery"
                      />
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <VideoPlayerModal
          currentVideo={currentVideo}
          modalIsOpen={modalIsOpen}
          handleCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}

TurboVideoGallery.defaultProps = {
  videos: [],
};

TurboVideoGallery.propTypes = {
  videos: PropTypes.arrayOf(),
};
