import { h } from 'preact';
import { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import { updateURLWithActiveVersion } from '%/utils/urlHandler';

export default class TechnicalSheetVersionsCarousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeVersionIndex: this.props.activeVersionIndex,
    }

    this.handleCardClick = this.handleCardClick.bind(this)
  }

  componentDidMount() {
    if (!this.versionsCarousel) return;

    makeCarousel(this.versionsCarousel, {
      gap: 30,
      perPage: 5,
      perMove: 5,
      pagination: false,
      breakpoints: {
        560: {
          perPage: 2
        },
        768: {
          perPage: 3
        },
        992: {
          perPage: 4
        },
        1270: {
          perPage: 5
        }
      },
    });
  }

  handleCardClick(event) {
    const card = event.target.closest('.versions-carousel__card');
    const versionIndex = card.dataset.versionIndex
    this.setState({ activeVersionIndex: versionIndex })

    this.props.handleVersionChange(versionIndex);

    if (this.props.showVersionInURL) {
      const activeVersion = this.props.versions[versionIndex];
      updateURLWithActiveVersion(activeVersion.slug);
    }
  }

  render() {
    if (this.props.versions.length <= 1) return

    return (
      <div
        ref={versionsCarousel => (this.versionsCarousel = versionsCarousel)}
        class="vehicle-details__versions-carousel versions-carousel splide"
      >
        <div class="splide__track" data-glide-el="track">
          <ul class="splide__list">
            {this.props.versions.map((version, index) => (
              <li
                className={`versions-carousel__card splide__slide${index == this.state.activeVersionIndex ? ' active' : ''}`}
                onClick={this.handleCardClick}
                data-version-index={index}
              >
                <div className="versions-carousel__card-header">
                  <i className="icon icon-check-mark"></i>
                  <img
                    className="versions-carousel__card-image"
                    src={version.item_image.middle_image}
                    alt={version.name || version.subtitle}
                    width="200"
                    height="140"
                  />
                </div>
                <div className="versions-carousel__card-content">
                  <p className="versions-carousel__card-title">
                    {version.name || version.subtitle}
                  </p>
                  <a
                    href="#offer-technical-sheet"
                    className="btn button button--link versions-carousel__card-cta"
                  >
                    <i className="icon icon-arrow-r"></i>
                    Ficha técnica
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

TechnicalSheetVersionsCarousel.propTypes = {
  versions: PropTypes.array,
  handleVersionChange: PropTypes.func,
  showVersionInURL: PropTypes.bool,
}
