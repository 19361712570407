import { VideoGalleryDataBuilder } from './VideoGalleryDataBuilder';
import { VimeoProviderAdapter } from './adapters/VimeoProviderAdapter';
import { YoutubeProviderAdapter } from './adapters/YoutubeProviderAdapter';

export const makeVideoGalleryDataBuilder = () => {
  const adaptersDictionary = {
    youtube: YoutubeProviderAdapter,
    vimeo: VimeoProviderAdapter,
  };

  const videoGalleryDataBuilder = new VideoGalleryDataBuilder(
    adaptersDictionary,
  );

  return videoGalleryDataBuilder;
};
