import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import FieldGroup from '../../FieldGroup';
import RadioInput from '../RadioInput';

export default class VehicleInExchangeFieldGroup extends Component {
  render() {
    return (
      <FieldGroup
        customClasses="contact-field-group"
      >
        <legend class="contact-field-group__label">Veículo na troca?</legend>
        <RadioInput
          name="veiculo-na-troca"
          options={[
            {
              label: 'Sim',
              value: 'sim',
            },
            {
              label: 'Não',
              value: 'nao'
            }
          ]}
          handleChange={this.props.handleChange}
        />
      </FieldGroup>
    )
  }
}

VehicleInExchangeFieldGroup.propTypes = {
  handleChange: PropTypes.func,
}
