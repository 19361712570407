const getModelPathName = () => {
  const pathname = window.location.pathname.split('/');
  const modules = ['novos', 'ofertas'];
  const moduleName = modules.find(module => pathname.includes(module));
  if (!moduleName) return pathname;

  const endOfPath = pathname.indexOf(moduleName) + 2;
  const path = pathname.slice(0, endOfPath);
  const newPath = path.join('/');

  return newPath;
};

export const getVersionSlug = () => {
  const fullPathname = window.location.pathname;
  const modelPathname = getModelPathName();

  const pathWhithoutModel = fullPathname
    .replace(modelPathname, '')
    .replace('/', '');

  return pathWhithoutModel;
};

export const updateURLWithActiveVersion = versionSlug => {
  const modelPath = getModelPathName();
  const updatedURL = `${window.location.origin}${modelPath}/${versionSlug}${window.location.search}`;

  window.history.pushState({}, null, updatedURL);
};
