import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class RadioInput extends Component {
  render() {
    const {
      customClasses,
      name,
      options,
      handleChange,
    } = this.props;

    return (
      <div className={classNames('form__radio-field', customClasses)}>
        {
          options && options.map(option => (
            <label className="form__radio-field-label">
              <input
                className="form__radio-field-input"
                type="radio"
                value={option.value}
                name={name}
                onChange={handleChange}
              />
              {option.label}
            </label>
          ))
        }
      </div>
    )
  }
}

RadioInput.propTypes = {
  customClasses: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
}
