// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';

class Sharethis extends Component {
  getURL() {
    return window.location.href;
  }

  render() {
    const currentURL = this.getURL();

    return (
      <div className="share__wrapper">
        <small className="share__label">Compartilhe essa oferta:</small>
        <div className="share__buttons">
          <a
            href={`http://www.facebook.com/share.php?u=${currentURL}`}
            className="share__button share__button--facebook"
            target="_blank"
            rel="noreferrer"
            aria-label="Compartilhar link no Facebook"
          >
            <i className="icon icon-facebook" />
          </a>
          <a
            href={`http://twitter.com/share?text=${currentURL}`}
            className="share__button share__button--x"
            target="_blank"
            rel="noreferrer"
            aria-label="Compartilhar link no X"
          >
            <i className="icon icon-x" />
          </a>
          <a
            href={`https://api.whatsapp.com/send?text=${currentURL}`}
            className="share__button share__button--whatsapp"
            data-action="share/whatsapp/share"
            target="_blank"
            rel="noreferrer"
            aria-label="Compartilhar link no Whatsapp"
          >
            <i className="icon icon-whatsapp" />
          </a>
        </div>
      </div>
    );
  }
}

export default Sharethis;
