export class YoutubeProviderAdapter {
  constructor(videoUrl) {
    this.videoUrl = videoUrl;
  }

  async adapt() {
    return this._getYoutubeVideoData();
  }

  async _getYoutubeVideoData() {
    const videoId = this._getYoutubeVideoId();
    if (!videoId) return null;

    const videoEmbedURL = this._getYoutubeVideoEmbed(videoId);
    const videoThumbURL = this._getYoutubeVideoThumb(videoId);

    const videoData = {
      id: videoId,
      embedURL: videoEmbedURL,
      thumb: videoThumbURL,
    };

    return videoData;
  }

  _getYoutubeVideoId() {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const regexResult = regex.exec(this.videoUrl);

    if (regexResult[1] === 'v') {
      return regexResult[2];
    }

    return null;
  }

  _getYoutubeVideoEmbed(videoId) {
    const youtubeBaseURL = 'https://www.youtube.com/embed';
    const youtubeEmbedParams = 'autoplay=1&rel=0';
    const youtubeEmbedURL = `${youtubeBaseURL}/${videoId}?${youtubeEmbedParams}`;

    return youtubeEmbedURL;
  }

  _getYoutubeVideoThumb(videoId) {
    const baseThumbURL = 'https://img.youtube.com/vi';
    const thumbResolution = 'mqdefault.jpg';
    const fullUrl = `${baseThumbURL}/${videoId}/${thumbResolution}`;

    return fullUrl;
  }
}
