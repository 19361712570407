// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';

export default class NewOffersCard extends Component {
  render() {
    const {
      title,
      subtitle,
      image,
      link,
      sealList,
      buttonText,
      secondaryCall,
      mainCall,
      featured,
      description,
      extra,
      badge,
    } = this.props;

    return (
      <div className="new-offer-card">
        <div className="new-offer-card__header">
          <a href={link}>
            <img
              className="new-offer-card__image"
              src={image}
              alt={title}
              width="200"
              height="150"
            />
          </a>
        </div>

        <div className="new-offer-card__content">
          <div
            className="new-offer-card__titles"
            data-match-height="new-offer-card-content"
          >
            <h3 className="new-offer-card__title">{title}</h3>
            <h4 className="new-offer-card__subtitle">{subtitle}</h4>
          </div>
          <div
            className="new-offer-card__seal-list"
            data-match-height="new-offer-card-list"
          >
            {sealList && sealList.length > 0 && (
              <ul className="card__seal-list">
                {sealList.map((text, index) => (
                  <li key={index} className="card__seal-list-item">
                    {text}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="new-offer-card__footer">
          <div className="card__footer offers__card-footer-offer-type">
            <div
              className="card__box-offer"
              data-match-height="box-offer-footer"
            >
              {secondaryCall !== '' && (
                <div className="card__trigger">
                  De <s>{secondaryCall.replace(/^de/i, '')}</s>
                </div>
              )}
              {mainCall !== '' && <div className="card__title">{mainCall}</div>}
              {featured !== '' && (
                <div className="card__trigger-value">{featured}</div>
              )}
              {description !== '' && (
                <div className="card__trigger-description">{description}</div>
              )}
              {extra !== '' && (
                <div className="card__trigger-extra">{extra}</div>
              )}
              {badge !== '' && (
                <div className="badge badge-primary badge--pill">{badge}</div>
              )}
            </div>
          </div>
          <a
            href={link}
            className="btn button button--primary button--block button--large card__cta"
          >
            {buttonText}
          </a>
        </div>
      </div>
    );
  }
}

NewOffersCard.defaultProps = {
  title: '',
  subtitle: '',
  image: '',
  link: '',
  buttonText: 'Estou Interessado',
  secondaryCall: '',
  mainCall: '',
  featured: '',
  description: '',
  extra: '',
  badge: '',
  sealList: [],
};

NewOffersCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  secondaryCall: PropTypes.string,
  mainCall: PropTypes.string,
  featured: PropTypes.string,
  description: PropTypes.string,
  extra: PropTypes.string,
  badge: PropTypes.string,
  sealList: PropTypes.arrayOf(PropTypes.string),
};
