import { h } from 'preact';
import { Component } from 'react';
import PropTypes, { string } from 'prop-types';

export default class StandardTechnicalSheet extends Component {
  constructor(props) {
    super(props)
    this.tabs = [
      {
        label: 'Especificações',
        id: 'detail_list',
      },
      {
        label: 'Conforto',
        id: 'comfort_list',
      },
      {
        label: 'Segurança',
        id: 'security_list',
      },
      {
        label: 'Conectividade',
        id: 'connectivity_list',
      },
    ]

    this.state = {
      activeTab: this.tabs[0].id,
      activeTabMobile: null,
    }

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleChangeTabMobile = this.handleChangeTabMobile.bind(this);
  }

  handleChangeTab(event) {
    const tabId = event.target.dataset.tabId;
    if (!tabId) return;
    this.setState({ activeTab: tabId })
  }

  handleChangeTabMobile(event) {
    const tabId = event.target.dataset.tabId;
    if (!tabId) return;

    const newActiveTab = tabId == this.state.activeTabMobile ? null : tabId
    this.setState({ activeTabMobile: newActiveTab })
  }

  contentItem(value) {
    const formattedValue = value.split(': ');
    const hasTitle = formattedValue.length > 1;

    if (hasTitle) {
      return (
        <p>
          <b className="technical-sheet__nav-item-title">{formattedValue[0]}:</b>
          {formattedValue[1]}
        </p>
      )
    } else {
      return value
    }
  }

  render() {
    const { id, datasheet } = this.props;
    const { activeTab, activeTabMobile } = this.state;

    return (
      <div className="technical-sheet" id={id}>
        <h2 className="technical-sheet__title">Ficha técnica</h2>
        <div className="technical-sheet__panel">
          <div className="technical-sheet__panel-header">
            <ul className="technical-sheet__nav">
              {
                this.tabs.map(tab => {
                  const hasTabContent = datasheet[tab.id].length > 0
                  return hasTabContent ?
                    (
                      <li
                        key={`${tab.id}`}
                        className={`technical-sheet__nav-item${tab.id == this.state.activeTab ? ' active' : ''}`}
                        data-tab-id={tab.id}
                        onClick={this.handleChangeTab}
                      >
                        {tab.label}
                      </li>
                    ) : null
                })
              }
            </ul>
          </div>
          <div className="technical-sheet__panel-body">
            {this.tabs.map(tab => (
              <div className="technical-sheet__tab-item" key={`${tab.id}`}>

                {tab.id == activeTab && (
                  <ul className="technical-sheet__content">
                    {datasheet && datasheet[activeTab].map((item, index) => (
                      <li
                        key={`${activeTab}-${index}`}
                        className="technical-sheet__content-item"
                      >
                        {this.contentItem(item)}
                      </li>
                    ))}
                  </ul>
                )}

                <div
                  className="technical-sheet__tab-toggle"
                  data-active={tab.id == activeTabMobile}
                  data-tab-id={tab.id}
                  onClick={this.handleChangeTabMobile}
                >
                  {tab.label} <i className="icon icon-arrow-d"></i>
                </div>

                {tab.id == activeTabMobile && (
                  <ul className="technical-sheet__content technical-sheet__content--mobile">
                    {datasheet && datasheet[activeTabMobile].map((item, index) => (
                      <li
                        key={`${activeTabMobile}-${index}`}
                        className="technical-sheet__content-item"
                      >
                        {this.contentItem(item)}
                      </li>
                    ))}
                  </ul>
                )}

              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

StandardTechnicalSheet.propTypes = {
  id: PropTypes.string,
  datasheet: PropTypes.object,
}
