export class VimeoProviderAdapter {
  constructor(videoUrl) {
    this.videoUrl = videoUrl;
  }

  async adapt() {
    return this._getVimeoVideoData();
  }

  async _getVimeoVideoData() {
    const videoId = this._getVimeoVideoId();

    if (!videoId) return null;

    const videoEmbedURL = this._getVimeoVideoEmbed(videoId);
    const videoThumbURL = await this._getVimeoVideoThumb(videoId);

    const videoData = {
      id: videoId,
      embedURL: videoEmbedURL,
      thumb: videoThumbURL,
    };

    return videoData;
  }

  _getVimeoVideoId() {
    const match = /vimeo\.com\/(\d+)/.exec(this.videoUrl);
    return match ? match[1] : null;
  }

  _getVimeoVideoEmbed(videoId) {
    const vimeoBaseURL = 'https://player.vimeo.com/video';
    const vimeoEmbedParams =
      'loop=false&autoplay=true&muted=false&gesture=media&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false';
    const vimeoEmbedURL = `${vimeoBaseURL}/${videoId}?${vimeoEmbedParams}`;

    return vimeoEmbedURL;
  }

  async _getVimeoVideoThumb(videoId) {
    const vimeoApiUrl = `http://vimeo.com/api/v2/video/${videoId}.xml`;
    const response = await fetch(vimeoApiUrl);
    const responseString = await response.text();
    const responseParsedData = new window.DOMParser().parseFromString(
      responseString,
      'text/xml',
    );

    const thumb =
      responseParsedData &&
      responseParsedData.getElementsByTagName('thumbnail_large')[0];

    if (!thumb) return null;

    const url = thumb.textContent;

    return url;
  }
}
