/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Component } from 'preact';
import PropTypes from 'prop-types';

export default class VideoPlayerModal extends Component {
  constructor(props) {
    super(props);

    this.getVideoContent = this.getVideoContent.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.modalContentRef && !this.modalContentRef.contains(event.target)) {
      this.props.handleCloseModal();
    }
  }

  getVideoContent() {
    const { currentVideo } = this.props;

    if (currentVideo.provider === 'youtube') {
      return (
        <iframe
          className="video-content"
          src={currentVideo.embedURL}
          width="560"
          height="315"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      );
    }

    if (currentVideo.provider === 'vimeo') {
      return (
        <iframe
          className="video-content"
          src={currentVideo.embedURL}
          title="Vimeo video player"
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        />
      );
    }

    return <p>Não foi possível reproduzir o vídeo</p>;
  }

  render() {
    const { modalIsOpen, currentVideo, handleCloseModal } = this.props;

    return (
      <div
        className="video-gallery__video-modal"
        onClick={this.handleClickOutside}
        onKeyDown={this.handleClickOutside}
      >
        <div className="my-modal" data-is-open={modalIsOpen} aria-hidden="true">
          <div className="my-modal__overlay">
            <div
              className="my-modal__container my-modal__container--large"
              ref={node => {
                this.modalContentRef = node;
              }}
            >
              <div className="my-modal__header my-modal__header--compact">
                <span className="my-modal__title" />
                <button
                  type="button"
                  className="my-modal__close-btn"
                  onClick={handleCloseModal}
                >
                  Fechar
                  <i className="icon icon-close" />
                </button>
              </div>
              <div className="my-modal__content my-modal__content--compact">
                {currentVideo ? (
                  <div className="video-container">
                    {this.getVideoContent()}
                  </div>
                ) : (
                  <span className="video-gallery__modal-loading">
                    Carregando vídeo...
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VideoPlayerModal.defaultProps = {
  currentVideo: null,
  modalIsOpen: false,
};

VideoPlayerModal.propTypes = {
  currentVideo: PropTypes.objectOf(),
  modalIsOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};
