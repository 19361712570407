import './components';
import './stores';
import '../shared';
import {
  makeCarousel,
  makeUnmountedCarousel,
} from '../../auto/utils/carousel/splide';
import serviceFactory from '../../auto/autoForceServiceFactory';

window.makeCarousel = makeCarousel;
window.makeUnmountedCarousel = makeUnmountedCarousel;

window.service = serviceFactory();
